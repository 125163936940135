<template>

  <div
    class="table-responsive"
    style="max-height: 500px; overflow-y: scroll"
  >
    <table class="table table-sm table-striped">
      <thead>
        <tr>
          <th />
          <th>Post Time</th>
          <th>Severity</th>
          <th>Message</th>
          <th>Class</th>
          <th>Object</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="alert in alerts"
          :key="alert.id"
        >
          <td>
            <b-badge variant="danger">
              <feather-icon
                v-if="alert.currentAlert === true"
                v-b-tooltip.hover.right
                icon="FlagIcon"
                title="Alert is active"
              />
            </b-badge>
          </td>
          <td>
            {{ alert.postTime | formatDateTimeTz(timezone) }}
          </td>
          <td>
            <b-badge
              :variant="getBadgeVariantBySeverity(alert.severity)"
            >
              {{ alert.severity }}
            </b-badge>
          </td>
          <td>
            {{ alert.message }}

            <feather-icon
              v-if="alert.description || alert.recommendedAction"
              :id="`${alert.id}_moreinfo`"
              icon="HelpCircleIcon"
            />

            <b-popover
              :target="`#${alert.id}_moreinfo`"
              triggers="hover"
            >
              <template #title>
                More information
              </template>
              <span>
                <div v-if="alert.description">
                  <h6>Description</h6>
                  {{ alert.description }}
                </div>

                <div
                  v-if="alert.recommendedAction"
                  style="margin-top: 10px"
                >
                  <h6>Recommended Action</h6>
                  {{ alert.recommendedAction }}
                </div>
              </span>
            </b-popover>
          </td>
          <td>{{ alert.class }}</td>
          <td>{{ alert.object }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

import {
  BPopover, VBTooltip, BBadge,
} from 'bootstrap-vue'

export default {
  components: {
    BPopover, BBadge,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    alerts: {
      type: Array,
      default: () => [],
    },
    timezone: {
      type: String,
      default: 'Etc/Utc',
    },
  },
  data() {
    return {

    }
  },
  methods: {
    getBadgeVariantBySeverity(severity) {
      switch (severity) {
        case 'ERROR':
        case 'CRITICAL':
          return 'danger'
        case 'WARNING':
          return 'warning'
        default:
          return 'info'
      }
    },
  },
}

</script>
